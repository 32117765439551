const initialState = {
    products: [],
    myOrders:[],
    currentUser:{}
  };
  
  const productReducer = (state = initialState, action) => {
    console.log("action",action)
    switch (action.type) {
      case "GET_ADMIN_PRODUCTS":
        return Object.assign({}, state, {
          products: action.data,
        });
        break;
        case "GET_PRODUCTS_TRENDS":
          return Object.assign({}, state, {
            productTrends: action.data,
          });
          break;
          case "GET_CURRENT_USER":
          return Object.assign({}, state, {
            currentUser: action.data,
          });
          break;
        case "GET_MY_ORDERS":
          return Object.assign({}, state, {
            myOrders: action.data,
          });
          break;
      case "DECREMENT":
        return { ...state, counter: state.counter - 1 };
      default:
        return state;
    }
  };
  
  export default productReducer;