// import service from "service";
import axios from "axios";
import {API} from "../apis"
import service from "./axios_service";
import { toast } from 'react-toastify';




const prepareDc = (id) => {
    let url = `api/v1/admin/delivery_challans/${id}/prepare`;
    return dispatch => {
    // const token = localStorage.getItem("AdminToken");

       return service.get(url, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((res) => {
              
                dispatch({
                    type: "PREPARED_DC",
                    data: res.data,
                });
                return true
            })
            .catch((error) => {
                return false;
                console.log("error",error);
            })
    }
}


const approveDC = (id,payload) => {
    let url = `api/v1/admin/delivery_challans/${id}/approve`;
    return dispatch => {
    // const token = localStorage.getItem("AdminToken");
console.log("level")
       return service.post(url,payload)
            .then((res) => {
                toast.success("D.C approved successfully")

              return true;
            })
            .catch((error) => {
                toast.success("D.C approved failed")

                return false;
            })
    }
}


const getListOfDc = (id,from,to) => {
    // 2024-08-10
    let url = `api/v1/admin/delivery_challans/${id}/list?from=${from}&to=${to}`;
    return dispatch => {

       return service.get(url)
            .then((res) => {
            //   let data=[];
            //   res.data.map((i)=>{
            //     i.delivery_challans.map((u)=>{
            //         let item={
            //             ...u,
            //             date:i.date
            //         }
            //         data.push(item);
            //     })
            //   });
               return res.data??[];
            })
            .catch((error) => {
                return [];
                console.log("error",error);
            })
    }
}


const downloadClientDc = (payload) => {
    const url = `api/v1/admin/delivery_challans/download`; // replace with your file URL
    return dispatch => {
        // const token = localStorage.getItem("token");
    // axios.post()
            service.post(url,payload,{
                withCredentials:true,
                responseType:"blob",
            })
                .then((res) => {
                    ////Open
                    const blob = new Blob([res.data], { type: 'application/pdf' });
                    const blobUrl = window.URL.createObjectURL(blob);
                    window.open(blobUrl, '_blank');

                })
                .catch((error) => {
                })
        }

  };


export {prepareDc,approveDC,getListOfDc,downloadClientDc};