import React, {useEffect, useState} from "react";

// class SelectBox extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = { value: props.value || "select" };
//   }

//   onChange(e) {
//     const selectedValue = e.target.value;
//     this.setState({ value: selectedValue });

//     if (this.props.onChange) {
//       this.props.onChange(selectedValue);
//     }
//   }

//   render() {
//     const { value } = this.state;
//     const className = `form-control orderupdatestatusdropdown ${value.toLowerCase().replace(/\s/g, '')}`;

//     return (
//       <select
//         value={value}
//         onChange={this.onChange.bind(this)}
//         className={className}
//       >
//         <option value="Delivered">Delivered</option>
//         <option value="On The Way">On The Way</option>
//         <option value="Dispatched">Dispatched</option>
//         <option value="Cancelled">Cancelled</option>
//       </select>
//     );
//   }
// }
const list =['order_placed', 'dispatched', 'delivered','cancelled'];
// 'invoice_approved'
//  const list =['Order placed', 'Dispatched', 'Delivered','Cancelled', 'Invoice approved'];
function SelectBox(props) {
  const [value, setValue] = useState("");

  useEffect(()=>{
    setValue(props.value??"");
      },[props])
      const getStatus = (item) => {
        let replaceValue = item.replace("_", " ");
        return replaceValue.charAt(0).toUpperCase() + replaceValue.slice(1);
      };
  const onChange=(e)=>{
    const selectedValue = e.target.value;
    setValue(selectedValue);

    if (props.onChange) {
      props.onChange(selectedValue);
    }
  }
  return (
    <select
      value={value}
      onChange={onChange}
      className={`form-control orderupdatestatusdropdown ${value.toLowerCase().replace(/\s/g, '')}`}
    >
      {list.map((i)=>{
        return <option value={i}>{getStatus(i)}</option>;
      })}
    </select>
  );
}

export default SelectBox;