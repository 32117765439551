import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import withRouter from "../Router/withRouter";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteIcon from "../../assets/icons/delete.svg";
import EditIcon from "../../assets/icons/edit.svg";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { downloadClientDc } from "../../services/Actions/invoices_api";
import DatePicker from "react-datepicker";
import { EditNote } from "@mui/icons-material";
import { getListOfDc } from "../../services/Actions/invoices_api";

import moment from "moment/moment";
function createData(invoice, invoicedate, status, amount) {
  return { invoice, invoicedate, status, amount };
}

// const rows1 = [
//   createData("38738725804", "28/07/2024", "Delivered", "$890"),
//   createData("38738725804", "28/07/2024", "On The Way", "$5486"),
//   createData("38738725804", "28/07/2024", "Delivered", "$890"),
//   createData("38738725804", "28/07/2024", "Delivered", "$890")
// ];

function DCDetailsTable(props) {
  //   const [rows, setRows] = useState(rows1);
  const [value, setValue] = useState("");
  const [clientDc, setClientDc] = useState([]);

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [modalShow, setModalShow] = useState(false);
  const [deliveryChallans, setDeliveryChallan] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    let i = props.data;
    // setClientDc(i);
  }, [props]);
  useEffect(() => {
    const currentDate = moment(); // Get the current date and time
    const newDate = currentDate.subtract(7, "days");
    console.log("end date ", newDate);
    setStartDate(newDate.toDate());
    getDcDetail(newDate, endDate);
  }, []);

  const getDcDetail = (from, to) => {
    const id = props.router?.params?.id ?? "";
    let first = moment(from).format("YYYY-MM-DD");
    let last = moment(to).format("YYYY-MM-DD");
    props.dispatch(getListOfDc(id, first, last)).then((data) => {
      setClientDc(data);
      console.log("asdasdasdas", data);
    });
  };
  const onApply = () => {
    getDcDetail(startDate, endDate);
  };
  const getStatus = (item) => {
    let replaceValue = item.replace("_", " ");
    return replaceValue.charAt(0).toUpperCase() + replaceValue.slice(1);
  };
  const handleMonthChange = (date, setDate) => {
    setDate(date);
    // getData(date);
  };
  const openModel = (items) => {
    setModalShow(true);
    setDeliveryChallan(items.delivery_challans ?? []);
  };
  const downloadDC = (i) => {
    let ids = [];
    i.delivery_challans?.map((u) => {
      ids.push(u.challan_id);
    });
    let payload = {
      challan_ids: ids,
    };
    props.dispatch(downloadClientDc(payload));
  };
  return (
    <div className="w-full">
      <div className="col-lg-12 d-flex align-items-end pb-2" style={{ marginTop: "30px" }}>
        <div className="col-lg-4 px-2">
          <span>Start Date</span>

          <div className="inputseachsection">
            <DatePicker
              selected={startDate}
              placeholderText="dd/mm/yyyy"
              onChange={(date) => handleMonthChange(date, setStartDate)}
              dateFormat="dd/MM/yyyy"
              className="form-control"
            />
          </div>
        </div>
        <div  className="col-lg-4 px-2">
          <span>End Date</span>
          <div className="inputseachsection">
            <DatePicker
              selected={endDate}
              placeholderText="dd/mm/yyyy"
              onChange={(date) => handleMonthChange(date, setEndDate)}
              dateFormat="dd/MM/yyyy"
              className="form-control"
            />
          </div>
        </div>
        <div className="col-4 px-2 d-flex justify-content-between align-items-center">
          <button onClick={onApply} className="button-section">
            Apply
          </button>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <Stack spacing={2}>
          <Pagination
            count={Math.ceil(
              clientDc.filter((item) =>
                value
                  ? item?.date?.toLowerCase().includes(value.toLowerCase())
                  : true
              ).length / rowsPerPage
            )}
            page={page}
            onChange={(e, newPage) => setPage(newPage)}
            showFirstButton
            showLastButton
          />
        </Stack>
      </div>
      <div className="clientinvoicedetailtablesection">
        <TableContainer
          component={Paper}
          style={{ borderRadius: "20px", boxShadow: "none" }}
        >
          <Table
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            style={{ backgroundColor: "#F1F1F1" }}
          >
            <TableHead>
              <TableRow>
                <TableCell align="center">D.C Date</TableCell>
                <TableCell align="center">Manage</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {clientDc
                .filter((item) =>
                  value
                    ? item?.challan_number
                        ?.toLowerCase()
                        .includes(value.toLowerCase())
                    : true
                )
                .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                .map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      align="center"
                      onClick={() => openModel(row)}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="invoiceandinvoicedatesection">
                        <h3>{row.date}</h3>
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <div
                        onClick={() => downloadDC(row)}
                        className="deleteandediticon d-flex justify-content-evenly"
                      >
                        <span> Download</span>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {/* Modal Component */}
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <div className="clientinvoicedetailtablesection">
            <TableContainer
              component={Paper}
              style={{ borderRadius: "20px", boxShadow: "none" }}
            >
              <Table
                sx={{ minWidth: 650 }}
                aria-label="simple table"
                style={{ backgroundColor: "#F1F1F1" }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Challan Number</TableCell>
                    <TableCell align="center">Order Number</TableCell>
                    <TableCell align="center">Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {deliveryChallans
                    .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                    .map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          align="center"
                          // onClick={() => setModalShow(true)}
                          style={{ cursor: "pointer" }}
                        >
                          <div className="invoiceandinvoicedatesection">
                            <h3>{row.challan_number}</h3>
                            {/* <h5>{row.status}</h5> */}
                          </div>
                        </TableCell>
                        <TableCell align="center">
                          <div className="deleteandediticon d-flex justify-content-evenly">
                            <span> {row.order_number}</span>
                          </div>
                        </TableCell>
                        <TableCell
                          className="invoicestatussection"
                          align="center"
                          style={{
                            color:
                              row.status === "delivered"
                                ? "#077252"
                                : row.status === "pending"
                                ? "#001C81"
                                : "black",
                          }}
                        >
                          {row.status}
                        </TableCell>
                        {/* <TableCell align="center" className="invoiceclientamountsection">{row.total_amount}</TableCell> */}

                        {/* <TableCell align="center">
                          <div className="deleteandediticon d-flex justify-content-evenly">
                            <span> Download</span>
                          </div>
                        </TableCell> */}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button onClick={() => setModalShow(false)}>Close</Button>
        </Modal.Footer> */}
      </Modal>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

function mapStateToProps(state) {
  return {
    prepareInvoices: state.procurement.prepareInvoices ?? {},
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DCDetailsTable)
);
