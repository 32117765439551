import React, {useState, useEffect} from "react";
import { connect } from "react-redux";
import withRouter from "../Router/withRouter";
import { MainHeader } from "../../Components/header";
import Sidebar from "../../Components/sidebar";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
// import Clientdetails from "./clientdetails";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {getOprationalList} from "../../services/Actions/insights_operational.js"
import Productpricetrends from "../Auth/productpricetrends.js";
import CustomYearSelect from "../Auth/customyearselect.js";
import moment from "moment";
import NoItems from "../Components/no_items.js";


const label = { inputProps: { "aria-label": "Checkbox demo" } };


function OprationalHome(props) {
  const [rows, setRows] = useState([]);
  const [value, setValue] = useState("");
  const [selectedYear, setSelectedYear] = useState("");

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  useEffect(() => {
    let currentYear=moment().format("YYYY");
    setSelectedYear(currentYear);
    getData(currentYear);
   
  }, []);
  useEffect(() => {
    setRows(props.operational);
    // setTrends(props.trends);
  }, [props]);

const getData=(year)=>{
  props.dispatch(getOprationalList(year));

}
  const onChangeYear =(val)=>{
    const year = moment(val).year();
    setSelectedYear(year);
    getData(year);

  }

  const onSelectItem=(i)=>{
    props.dispatch({
      type: "SET_OPERATIONAL",
      data: i,
    });
    props.router.navigate("/dashboard/operational/add");
  }

  return (
    <div className="w-full">
      {/* <div className="headerandsidebar">
        <MainHeader />
        <Sidebar />
      </div> */}
      <div className="main-container">
        <div className="container">
          <div className="row">
            {/* <div className="col-12">
              <h2 className="importantheader">PRICE TRENDS</h2>
            </div>
            <div className="col-12 mb-5">
              <Productpricetrends />
            </div> */}
            <div class="col-12 d-flex justify-content-end align-items-center" style={{marginBottom: "20px"}}>
              <div className="col-6">
                <h3 className="primaryheader mb-0">Operational List</h3>
              </div>
              <div className="col-6 text-end">
                <button onClick={()=> props.router.navigate("/dashboard/operational/add")} className="nav-button-section active">
                ADD OPERATIONAL
                </button>
              </div>
            </div>
            <div className="col-lg-12 d-flex align-items-center justify-content-between mb-5">
              {/* <div className="inputseachsection col-4">
                <input
                  className="form-control mr-sm-2"
                  type="search"
                  placeholder="Search..."
                  aria-label="Search"
                  onChange={(e)=>setValue(e.target.value)}
                />
              </div> */}
              <div>
                <CustomYearSelect
                  value={selectedYear}
                  onChange={onChangeYear}
                />
              </div>
              {rows.length>0&&<div className="d-flex justify-content-end">
                <Stack spacing={2}>
                <Pagination
                    count={Math.ceil(rows.length / rowsPerPage)}
                    page={page}
                    onChange={(e,newPage)=>setPage(newPage)}
                    showFirstButton
                    showLastButton
                  />
                </Stack>
              </div>}
            </div>
            {/* <div
              className="d-flex align-items-center"
              style={{ marginLeft: "10px" }}
            >
              <Checkbox {...label} />
              <h4
                className="selectalltextsection m-0"
                style={{ cursor: "pointer" }}
              >
                Select all
              </h4>
            </div> */}
           {rows.length>0? <div className="productssection">
              {/* <Clientdetails /> */}
              <div className="clientdetailstablesection mb-4">
        <TableContainer component={Paper} style={{ borderRadius: "20px" }}>
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              style={{ backgroundColor: "transparent !important" }}
            >
              <TableHead>
                {/* <TableCell align="left">
                  
                </TableCell> */}
                {/* <TableCell align="center">Year</TableCell> */}
                <TableCell align="center">Month</TableCell>
                <TableCell align="center">Utillization Percent</TableCell>
                <TableCell align="center">Monthly Fixed Cost</TableCell>
                <TableCell align="center">Absolute Fixed Cost</TableCell>
                <TableCell align="center">Variable Cost Transportation</TableCell>
                {/* <TableCell align="center"></TableCell> */}
              </TableHead>
              <TableBody>
                {rows.slice((page - 1) * rowsPerPage, page * rowsPerPage)
                    .map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    
                    {/* <TableCell align="center">{row.name}</TableCell> */}
                    <TableCell align="center">{row?.month??""}</TableCell>
                    <TableCell align="center">{(row?.utilization_percent??0).toFixed(2)}</TableCell>
                    <TableCell align="center">{(row?.monthly_fixed_cost??0).toFixed(2)}</TableCell>
                    <TableCell align="center">
                    {(row?.absolute_fixed_cost??0).toFixed(2)}
                    </TableCell>
                    <TableCell align="center">
                    {(row?.variable_cost_transportation??0).toFixed(2)}
                    </TableCell>
                    {/* <TableCell align="center">
                        <div className="d-flex deleteandediticonsection" style={{gap: "20px"}}>
                        <i class="material-icons" onClick={()=>onSelectItem(row)} style={{fontSize:"20px"}}>edit</i>
                        </div>
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
        </TableContainer>
        {/* <div className="col-12 text-center my-5">
                <button className="nav-button-section active">
                ADD TO CLIENT LIST
                </button>
              </div> */}
      </div>
            </div>: <NoItems />}
          </div>
        </div>
      </div>
    </div>
  );
}
function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}
function mapStateToProps(state) {
  return {
    operational: state.insights.operational??[]

  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OprationalHome)
);
