const initialState = {
    prepareDC: {},
    };
    
    const invoiceReducer = (state = initialState, action) => {
      console.log("action",action)
      switch (action.type) {
              case "PREPARED_DC":
              return Object.assign({}, state, {
                prepareDC: action.data,
              });
              break;
        default:
          return state;
      }
    };
    
    export default invoiceReducer;