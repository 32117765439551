import React from "react";
import {createBrowserRouter, createRoutesFromElements, Route, Navigate} from "react-router-dom";
import PrivateRoute, {isAuthenticated} from "./privateRoute";
import HomeCostPrize from "../Auth/homeCostPrize";
import HomeSellingPrize from "../Auth/homeSellingPrize";
import HomeOrders from "../Auth/homeOrders";
import HomeInvoice from "../Auth/homeInvoice";
import Clients from "../Auth/clients";
import Clientcredential from "../Auth/clientcredential";
import Clientinvoice from "../Auth/clientinvoice";
import Clientinvoicedetails from "../Auth/clientinvoicedetails";
import Login from "../Auth/login";
import Dashboard from "../Auth/dashboard";
import HomeLaunch from "../Auth/homeLaunch";
import Landing from "../Auth/landing";
import NewProduct from "../Product/newProduct";
import Products from "../Product/products";
import Billings from "../Auth/billings";
import Insights from "../Auth/insights";
import Operational from "../Oprational/add_operational";
import OperationalHome from "../Oprational/oprational"
// import {} 
const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/">
         <Route path="/" element={isAuthenticated()? <Navigate to="/dashboard/home/costPrize" replace />:<Navigate to="/landing" replace />} />
      <Route path="/login" element={<Login />} />
      <Route  path="/landing" element={<Landing />} />
      <Route
        path="/dashboard"
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      >
        <Route path="home/:tab" element={<HomeLaunch />} />
        <Route path="clients" element={<Clients />} />
        <Route path="clientcredential/:type/:id?" element={<Clientcredential />} />
        <Route path="clientinvoice" element={<Clientinvoice />} />
        <Route path="clientinvoicedetails/:id" element={<Clientinvoicedetails />} />
        <Route path="product/:type/:id?" element={<NewProduct />} />
        <Route path="products" element={<Products />} />
        <Route path="billings" element={<Billings />} />
        <Route path="insights/:id?" element={<Insights />} />
        <Route path="operational" element={<OperationalHome />} />
        <Route path="operational/add/:id?" element={<Operational />} />
      </Route>
      <Route path="*" element={<h1>Page not found</h1>} />
    </Route>
    )
  );
  export default router;