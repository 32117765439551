import React, { useRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import withRouter from "../Router/withRouter";
import GreenTriangle from "../../assets/icons/greentriangle.svg";
import RedTriangle from "../../assets/icons/redtriangle.svg";
import EqualToImage from "../../assets/icons/equaltoicon.svg";
import { getProductsTrends } from "../../services/Actions/client";
import NoItems from "../Components/no_items";

function Clientcredential(props) {
  const scrollContainerRef = useRef(null);
  const scrollSpeed = 1;
  const [products, setTrends] = useState([]);
  const [extendedProducts, setExTrends] = useState([]);

  // const products = [
  //   { name: "POTATOES", price: "$12.00", changePrice: "$14.00", isPriceUp: true },
  //   { name: "TOMATOES", price: "$16.00", changePrice: "$24.00", isPriceUp: true },
  //   { name: "KEERAI", price: "$18.00", changePrice: "$14.00", isPriceUp: false },
  //   { name: "G.PEPPERS", price: "$18.00", changePrice: "$23.00", isPriceUp: true },
  //   { name: "ONIONS", price: "$14.00", changePrice: "$9.00", isPriceUp: false },
  //   { name: "BEANS", price: "$14.00", changePrice: "$9.00", isPriceUp: true },
  //   { name: "POTATOES", price: "$12.00", changePrice: "$14.00", isPriceUp: true },
  //   { name: "TOMATOES", price: "$10.00", changePrice: "$8.00", isPriceUp: false },
  //   { name: "ONIONS", price: "$8.00", changePrice: "$9.00", isPriceUp: true },
  // ];

  // Duplicate the product array to simulate an infinite loop
  useEffect(() => {
    setTrends(props.trends);
    let items = [...props.trends, ...props.trends];
    console.log("trendssfdfsdfsdf", items);
    setExTrends(items);
  }, [props]);
  useEffect(() => {
    props.dispatch(getProductsTrends());
    console.log("pros", props);
  }, []);
  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    let scrollInterval = setInterval(() => {
      if (scrollContainer) {
        scrollContainer.scrollLeft += scrollSpeed;
        if (scrollContainer.scrollLeft >= scrollContainer.scrollWidth / 2) {
          // Smoothly reset to the start without visual break
          scrollContainer.scrollLeft = 0;
        }
      }
    }, 10); // Adjust the interval to control smoothness and speed

    return () => clearInterval(scrollInterval); // Clean up on unmount
  }, []);

  return (
    <div className="w-full totalproductpricetrendsection">
      {extendedProducts.length>0? <div
        className="scroll-container"
        ref={scrollContainerRef}
        style={{
          display: "flex",
          overflowX: "scroll",
          scrollBehavior: "smooth",
          whiteSpace: "nowrap",
        }}
      >
        {extendedProducts.map((product, index) => (
          <div
            key={index}
            className="col-3 productpricetrendsection text-center"
            style={{ display: "flex",flexDirection: "column", justifyContent: "center", alignItems: "center" }}
          >
            <h4>{product.product_name}</h4>
            <h4>₹{product.today_price}</h4>
            <div className="d-flex justify-content-center">
              {product.trend === "same" ? (
                <img
                  src={EqualToImage} // Add the path to your 'equal to' image here
                  alt="equal-to"
                  className="equal-to-image me-2"
                />
              ) : (
                <img
                  src={
                    product.trend === "decreased" ? RedTriangle : GreenTriangle
                  }
                  alt="triangle-image"
                  className={`${
                    product.trend === "decreased"
                      ? "red-triangle"
                      : "green-triangle"
                  } me-2`}
                />
              )}
              <h4 className="mb-0">
              ₹{Math.abs(product.today_price - product.yesterday_price)}
              </h4>
            </div>
          </div>
        ))}
      </div>: <NoItems />}
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

function mapStateToProps(state) {
  return {
    trends: state.product.productTrends ?? [],
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Clientcredential)
);
