import React, { useEffect, useState, useRef } from "react";
import Logo from "../assets/farmaze_logo.png";
import Notification from "../assets/icons/notification.svg";
import User from "../assets/icons/user.svg";
import { isAuthenticated } from "../Pages/Router/privateRoute";
import { connect } from "react-redux";
import withRouter from "../Pages/Router/withRouter";

const HeaderProps = (props) => {
  const isLogin = isAuthenticated();
  const [value, setValue] = useState("dashboard");

  useEffect(() => {
    let { pathname } = props.router.location;
    let val = pathname.toLowerCase().includes("dashboard")
      ? "dashboard"
      : "myorders";
    setValue(val);
  }, [props]);

  return (
    <div className="w-full sticky-wrapper-header">
      <div className="header py-2">
        <div className="container px-0">
          <div className="row d-flex justify-content-between align-items-center">
            <div className="col">
              <a href="/home">
                <img src={Logo} width={60} alt="Logo" />
              </a>
            </div>
            {/* {isLogin && (
              <div className="col d-flex justify-content-end align-items-center navbar-section">
                <h4>
                  <a
                    className={`${
                      value === "dashboard" ? "active-option" : ""
                    }`}
                    href="/dashboard/0"
                  >
                    Home
                  </a>
                </h4>
                <h4>
                  <a
                    className={`${
                      value === "myorders" ? "active-option" : ""
                    }`}
                    href="/MyOrders"
                  >
                    My Orders
                  </a>
                </h4>
                <button className="button-section">My Profile</button>
              </div>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

const MainHeaderProps = (props) => {
  const isLogin = isAuthenticated();
  const [value, setValue] = useState("dashboard");
  const [isProfileMenuOpen, setProfileMenuOpen] = useState(false);
  const profileMenuRef = useRef(null);
  const overlayDivRef = useRef(null);

  useEffect(() => {
    let { pathname } = props.router.location;
    let val = pathname.toLowerCase().includes("dashboard")
      ? "dashboard"
      : "myorders";
    setValue(val);
  }, [props]);

  // Close dropdown if clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        profileMenuRef.current &&
        !profileMenuRef.current.contains(event.target) &&
        overlayDivRef.current &&
        !overlayDivRef.current.contains(event.target)
      ) {
        setProfileMenuOpen(false);
        overlayDivRef.current.classList.add("d-none"); // Hide overlay when clicked outside
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [profileMenuRef]);

  const toggleProfileMenu = () => {
    setProfileMenuOpen(!isProfileMenuOpen);
    if (overlayDivRef.current) {
      overlayDivRef.current.classList.toggle("d-none"); // Show/hide overlay
    }
  };

  const onClickLogout = () => {
    localStorage.clear();
    props.router.navigate("/login");
  };

  return (
    <div className="w-full sticky-wrapper-main-header">
      <div className="header py-2">
        <div className="container px-0">
        <div className="overlaydivsection d-none" ref={overlayDivRef}></div>
          <div className="row d-flex justify-content-between align-items-center">
            <div className="col-8 d-flex align-items-center">
              <div className="col-4">
                <a>
                  <img src={Logo} width={60} alt="Logo" />
                </a>
              </div>
              <div className="col-8">
                <div>
                  <div className="inputseachsection" style={{ width: "60%" }}>
                    <input
                      className="form-control mr-sm-2"
                      type="search"
                      placeholder="Search..."
                      aria-label="Search"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4 d-flex justify-content-end align-items-center helpandreportsection">
              <h4 className="disabled mb-0">Help</h4>
              <h4 className="disabled mb-0">Report</h4>
              <h4 className="notificationsection disabled mb-0">
                <a
                  className={` ${value === "myorders" ? "active-option" : ""}`}
                >
                  {/* <img
                    src={Notification}
                    alt="icon"
                    className="notification headericonsection"
                  ></img> */}
                  <svg className="notification headericonsection" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.3898 0C16.9855 0 13.7207 1.35234 11.3135 3.75952C8.90632 6.1667 7.55398 9.43153 7.55398 12.8358V19.305C7.55425 19.5895 7.48833 19.8701 7.36144 20.1247L4.21301 26.4197C4.05921 26.7273 3.98659 27.069 4.00204 27.4125C4.01749 27.7561 4.1205 28.0899 4.30129 28.3824C4.48208 28.6749 4.73464 28.9163 5.03499 29.0837C5.33533 29.2512 5.6735 29.339 6.01735 29.339H34.7622C35.1061 29.339 35.4442 29.2512 35.7446 29.0837C36.0449 28.9163 36.2975 28.6749 36.4783 28.3824C36.6591 28.0899 36.7621 27.7561 36.7775 27.4125C36.793 27.069 36.7203 26.7273 36.5665 26.4197L33.4199 20.1247C33.2924 19.8703 33.2259 19.5896 33.2256 19.305V12.8358C33.2256 9.43153 31.8732 6.1667 29.4661 3.75952C27.0589 1.35234 23.794 0 20.3898 0ZM20.3898 34.84C19.2517 34.8406 18.1414 34.4882 17.212 33.8314C16.2826 33.1746 15.5798 32.2456 15.2004 31.1726H25.5791C25.1997 32.2456 24.4969 33.1746 23.5675 33.8314C22.6381 34.4882 21.5279 34.8406 20.3898 34.84Z" fill="#b0b0b0"/>
                  </svg>
                </a>
              </h4>

              {/* Profile Icon Section with Dropdown */}
              <h4 className="profilesection mb-0">
                <div onClick={toggleProfileMenu} className="profile-icon-wrapper">
                  {/* <img
                    src={User}
                    alt="icon"
                    className="user headericonsection"
                  /> */}
                  <svg className="user headericonsection" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.4737 9.47368C10.4737 14.6968 14.7242 18.9474 19.9474 18.9474C25.1705 18.9474 29.421 14.6968 29.421 9.47368C29.421 4.25053 25.1705 0 19.9474 0C14.7242 0 10.4737 4.25053 10.4737 9.47368ZM36.7895 40H38.8947V37.8947C38.8947 29.7705 32.2821 23.1579 24.1579 23.1579H15.7368C7.61053 23.1579 1 29.7705 1 37.8947V40H36.7895Z" fill="#382E2C"/>
                  </svg>
                </div>

                {/* Profile Dropdown Menu */}
                {isProfileMenuOpen && (
                  <div
                    className="profilemenusection"
                    ref={profileMenuRef}
                    style={{
                      position: "absolute",
                      backgroundColor: "#fff",
                      right: "0",
                      top: "60px",
                      padding: "25px",
                      zIndex: 9999,
                      boxShadow: "0px 8px 24px rgba(149, 157, 165, 0.2)",
                      width: "max-content",
                    }}
                  >
                    <div className="profilemenusectionheader d-flex">
                      <div
                        className="profilemenuprofileprofilesection"
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "50%",
                          backgroundImage: `url(${User})`,
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                        }}
                      ></div>
                      <div className="profilemenuprofiledetailssection" style={{ marginLeft: "16px" }}>
                        <h6>Hi</h6>
                        <h3>{props.user?.first_name +props.user?.last_name}</h3>
                        <h5>{props.user?.email}</h5>
                      </div>
                    </div>
                    <hr />
                    <div className="profilelistoptionsection">
                      <div onClick={onClickLogout} className="profilelistoptions d-flex align-items-center">
                        <i className="fa fa-power-off"></i>
                        <h3 className="logout ms-3">Log Out</h3>
                      </div>
                    </div>
                  </div>
                )}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

function mapStateToProps(state) {
  return {
    user: state.product.currentUser,
  };
}

const Header = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HeaderProps)
);
const MainHeader = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MainHeaderProps)
);

export { Header, MainHeader };