// import React from "react";
// import { connect } from "react-redux";
// import withRouter from "../Router/withRouter";
// import {
//   RouterProvider
// } from "react-router-dom";
// import {createBrowserRouter, createRoutesFromElements, Route} from "react-router-dom";

// import { MainHeader } from "../../Components/header";
// import Sidebar from "../../Components/sidebar";
// import Navbar from "../../Components/navbar";

// ////Pages

// import Clients from "../Auth/clients";
// import Clientcredential from "../Auth/clientcredential";
// import Clientinvoice from "../Auth/clientinvoice";
// import Login from "../Auth/login";
// // import Dashboard from "../Auth/dashboard";
// import HomeLaunch from "../Auth/homeLaunch";
// import Landing from "../Auth/landing";
// import NewProduct from "../Product/newProduct";
// function Dashboard(props) {
    
//   return (
//     <div className="w-full">
//       <div className="headerandsidebar">
//         <MainHeader />
//         <Sidebar />        
//       </div>
//       <Navbar />
//       <RouterProvider
//     router={router}
//     fallbackElement={<div />}
//   />
//       </div>
//   );
// }
// function mapDispatchToProps(dispatch) {
//   return {
//     dispatch,
//   };
// }
// function mapStateToProps(state) {
//   return {};
// }

// export default withRouter(
//   connect(mapStateToProps, mapDispatchToProps)(Dashboard)
// );

// const router = createBrowserRouter(
//   createRoutesFromElements(
//     <Route path="/">
     
//       {<Route path="/home/:tab" element={<HomeLaunch />} /> }
//       {<Route path="/clients" element={<Clients />} />}
//       {<Route path="/clientcredential" element={<Clientcredential />} />}
//      { <Route path="/clientinvoice" element={<Clientinvoice />} />}
//       <Route path="/product/create" element={<NewProduct />} />
//     </Route>
//   )
// );


import React, {useEffect} from "react";
import { Outlet } from "react-router-dom";
import { MainHeader } from "../../Components/header";
import { connect } from "react-redux";
import withRouter from "../Router/withRouter";
import Sidebar from "../../Components/sidebar";
import Navbar from "../../Components/navbar";
import {getCurrentUser} from "../../services/Actions/client"

function DashboardLayout(props) {

  useEffect(()=>{
    props.dispatch(getCurrentUser());
  },[])
  return (
    <div className="w-full">
      <div className="headerandsidebar">
        <MainHeader />
        <Sidebar />
      </div>
      {/* <Navbar /> */}
      <div className="main-content">
        {/* The routed content will be rendered here */}
        <Outlet />
      </div>
    </div>
  );
}


function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

function mapStateToProps(state) {
  return {};
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DashboardLayout)
);
