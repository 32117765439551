import { combineReducers } from 'redux'
import productReducer from './product';
import procurement from "./procurement";
import invoiceReducer from './invoices';
import insightsReducer from './insightsAndOperational';
// Combine Reducers
const rootReducer = combineReducers({
  procurement: procurement,
  product:productReducer,
    invoice:invoiceReducer,
    insights:insightsReducer
    // Add other reducers here if you have more
  });
  
  export default rootReducer;