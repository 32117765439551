import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import withRouter from "../Router/withRouter";

import Table from "react-bootstrap/Table";
import BackIcon from "../../assets/icons/back-icon.svg";
import CustomYearSelect from "./customyearselect";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import ApexCharts from "apexcharts";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { IconButton } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import {getDailySummary, getYearlySummary} from "../../services/Actions/insights_operational";
import { getClients } from "../../services/Actions/client";
 import moment from "moment/moment";
 import NoItems from "../Components/no_items";


dayjs.extend(quarterOfYear);

function Insights(props) {
  const [selectedYear, setSelectedYear] = useState("");
  const [minDate, setMinDate] = useState("");
  const [maxDate, setMaxDate] = useState("");
  const [selectedClient, setClients] = useState("");
  const chartRef = useRef(null);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [open, setOpen] = useState(false);

  // const maxDate = dayjs().set(year); 

  ///Page
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [value, setValue] = useState("");


  const handleSelectChange = (event) => {
    let id=event.target.value;
    setClients(id);
    let currentYear=moment().format("YYYY");
    setSelectedYear(currentYear);
    setSelectedDate(dayjs());
    initData(id,currentYear);
  };

  const handleDatePickerOpen = () => {
    setOpen(true);
  };

  const handleDateChange = (newDate) => {
    console.log("New Date",newDate);
    setSelectedDate(newDate);
    getTabelData(newDate,selectedClient);

    setOpen(false);
  };

  const totalPages = 65;
  const handlePaginationChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    let items= props.monthlyOverview??[];
    let data=[];
    items.map((i)=>{
      data.push({ x: i?.month??"", y: i?.quantity_sold??0 });
    });
const highestTotalRevenue = items.reduce((max, item) => Math.max(max, item.quantity_sold), 0);
    const options = {
      series: [
        {
          name: "Quantity Sold",
          data: data,
        },
      ],
      chart: {
        type: "bar",
        height: 380,
      },
      colors: ["#00A882"],
      xaxis: {
        type: "category",
        title: {
          text: "Months", // Title for X-axis
          style: {
            fontSize: "14px",
            fontWeight: "bold",
            fontFamily: "Arial, sans-serif",
          },
        },
        labels: {
          formatter: (val) => val, // Uppercase month names
        },
      },
      yaxis: {
        min: 0,
        max: highestTotalRevenue,
        tickAmount: 5,
        labels: {
          formatter: (val) => val.toFixed(0),
        },
        title: {
          text: "Quantity Sold", // Title for Y-axis
          style: {
            fontSize: "14px",
            fontWeight: "bold",
            fontFamily: "Arial, sans-serif",
          },
        },
      },
      tooltip: {
        x: {
          formatter: (val) => dayjs(val).format("MMM YYYY").toUpperCase(), // Tooltip with uppercase month and year
        },
      },
    };

    const chart = new ApexCharts(chartRef.current, options);
    chart.render();

    return () => {
      chart.destroy();
    };
  }, [props.monthlyOverview]);

  useEffect(() => {
    let currentYear=moment().format("YYYY");
    setSelectedYear(currentYear);
    // setCalendarDate(currentYear);
    props.dispatch(getClients());
    // console.log("Current year",moment().format("YYYY"));
  }, []);

  useEffect(() => {
    if(props.clients.length>0){
    const paramId = props.router?.params?.id ?? "";

      // if(paramId!=""){
      initData(paramId!=""?paramId:props.clients[0].id, selectedYear);
      // }
    }
  }, [props.clients]);

  const onChangeClients =()=>{
    let currentYear=moment().format("YYYY");
    setSelectedYear(currentYear);
    setSelectedDate(dayjs());
  }

  useEffect(() => {
    let items= props.monthlyOverview??[];
    let totalR=[];
    let totalC=[];
    let categories=[];
    items.map((i)=>{
      categories.push(i.month??"");
      totalR.push(i.total_revenue??"");
      totalC.push(i.total_cost??"");
    })
    const highestTotalCost = items.reduce((max, item) => Math.max(max, item.total_cost), 0);
const highestTotalRevenue = items.reduce((max, item) => Math.max(max, item.total_revenue), 0);
const higherValue = Math.max(highestTotalCost, highestTotalRevenue);
    const salesSummaryOptions = {
      series: [
        {
          name: "Total Revenue",
          data: totalR,
        },
        {
          name: "Total Cost",
          data: totalC,
        },
      ],
      chart: {
        type: "line",
        height: 350,
        // background: "#f4f4f4",
        toolbar: {
          show: false,
        },
      },
      colors: ["#FFAA64", "#FF4560"], // Orange and Red
      xaxis: {
        categories: categories,
        title: {
          text: "Months",
          style: {
            fontSize: "14px",
            fontWeight: "bold",
          },
        },
      },
      yaxis: {
        title: {
          text: "Amount",
          style: {
            fontSize: "14px",
            fontWeight: "bold",
          },
        },
        min: 0,
        max: higherValue,
        tickAmount: 5,
        labels: {
          formatter: (val) => val.toLocaleString("en-IN"),
        },
      },
      markers: {
        size: 4,
        strokeColors: ["#FFAA64", "#FF4560"], // Marker colors
      },
      stroke: {
        width: 3,
        curve: "smooth",
      },
      legend: {
        position: "top",
        horizontalAlign: "center",
        markers: {
          width: 12,
          height: 12,
          radius: 12,
        },
      },
      grid: {
        borderColor: "#e0e0e0",
        strokeDashArray: 4,
      },
    };
    
  
    const chart = new ApexCharts(document.querySelector("#sales-summary-chart"), salesSummaryOptions);
    chart.render();
  
    return () => chart.destroy(); // Cleanup chart on component unmount
}, [props.monthlyOverview]);

  const initData=(id,year)=>{
    console.log("Section")
    setClients(id)
    getTabelData(selectedDate,id);
    props.dispatch(getYearlySummary(year,id));
  }
 const onChangeYear =(val)=>{
    const year = moment(val).year();
    setSelectedYear(year);

    const month = selectedDate.month(); // Month index (0 for January, 1 for February, etc.)
    const date = selectedDate.date();
    const newDate = dayjs().set('year', year); 
    setSelectedDate(newDate);

    console.log("Selected year",year,newDate)
    initData(selectedClient,val);
  }
  const getTabelData = (date,id) => {
    console.log("date sfsdfsdfsdf",date);
    props.dispatch(getDailySummary(dayjs(date).set('year', selectedYear).format("YYYY-MM-DD"),id));

  };
  const shouldDisableDate = (date) => {
    return date.year() != selectedYear; // Disable all dates in 2023
  };

  return (
    <div>
      <div className="main-container insightssection">
        <div className="container">
          <div className="row">
            {props.router?.params?.id &&<div
            onClick={()=> props.router.navigate(-1)}
              className="col-12 d-flex justify-content-start align-items-center"
              style={{ marginBottom: "20px" }}
            >
              <img src={BackIcon} alt="icon" className="gobackicon" />
              <h6>Go Back</h6>
            </div>}
            <div
              className="col-12 d-flex justify-content-between align-items-center mt-3 analyticsyeardropdownsection"
              style={{ marginBottom: "20px" }}
            >
              <div>
                <FormControl
                  className="customdropdownsection"
                  sx={{ m: 1, minWidth: 450 }}
                >
                  <Select
                    value={selectedClient}
                    onChange={handleSelectChange} // Updated to handleSelectChange
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    {props.clients?.map((i)=><MenuItem value={i.id}>{i.name??""}</MenuItem>)}
                    {/* <MenuItem value={20}>Customers</MenuItem> */}
                  </Select>
                </FormControl>
              </div>
              <div>
                <CustomYearSelect
                  value={selectedYear}
                  onChange={onChangeYear}
                />
              </div>
            </div>
            <div
              className="col-12 d-flex justify-content-start align-items-center mt-3"
              style={{ marginBottom: "20px" }}
            >
              <div className="allpricecardsection costpricesection col">
                <h3>{(props.annualOverview?.total_annual_cost??0)?.toFixed(2)} INR</h3>
                <h5>Cost Price</h5>
              </div>
              <div className="allpricecardsection sellingpricesection col">
                <h3>{(props.annualOverview?.total_annual_revenue??0)?.toFixed(2)} INR</h3>
                <h5>Total Revenue</h5>
              </div>
              <div className="allpricecardsection grosspricesection col">
                <h3>{(props.annualOverview?.total_gross_profit_or_loss??0)?.toFixed(2)} INR</h3>
                <h5>Profit or Loss</h5>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="sales-summary-container">
              <h3 className="sales-summary-title">Sales Summary</h3>
              <div
                className="sales-summary-chart"
                id="sales-summary-chart"
              ></div>
            </div>
          </div>
          <div className="row">
            <div className="barchartdivsection">
              <h3 className="monthlyquantitysoldheadersection">
                Monthly Quantity Sold
              </h3>
              <div id="chart" ref={chartRef}></div>
            </div>
          </div>
          <div className="align-items-sm-center row justify-content-between  mb-5 d-flex">
            <div className="d-flex align-items-center justify-content-between">
            <h3>Date</h3>
            
            <div className="my-3 customdatepickersection">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div
                  onClick={handleDatePickerOpen}
                  className="customdatepickerdivsection"
                >
                  <DatePicker
                    // views={["year","month", "day"]}
                    value={selectedDate}
                    // minDate={minDate}
                    // maxDate={maxDate}
                    onChange={handleDateChange}
                    open={open} // Open DatePicker when the div is clicked
                    onClose={() => setOpen(false)} // Close DatePicker when clicking outside
                    shouldDisableDate={shouldDisableDate}
                  />
                </div>
              </LocalizationProvider>
            </div>
            </div>
            {props.dailyProducts.length >0 && <div className="inputseachsection col-4">
                <input
                  className="form-control mr-sm-2"
                  type="search"
                  placeholder="Search..."
                  aria-label="Search"
                  onChange={(e) => setValue(e.target.value)}
                />
              </div>}
          </div>
          <div className="row productsalestablerowsection">
            <h3>Product Sales</h3>
            {props.dailyProducts.filter((item) =>
                    value
                      ? item.item_name
                          .toLowerCase()
                          .includes(value.toLowerCase())
                      : true
                  ).length>0?<div>
            <Table className="table-borderless productsalestablesection">
              <thead>
                <tr>
                  <th>Product Name</th>
                  <th>Quantity</th>
                  <th>Cost Price</th>
                  <th>Sales Price</th>
                  <th>Gross Profit</th>
                </tr>
              </thead>
              <tbody>
                {props?.dailyProducts?.filter((item) =>
                          value
                            ? item.item_name
                                .toLowerCase()
                                .includes(value.toLowerCase())
                            : true
                        ).slice((page - 1) * rowsPerPage, page * rowsPerPage).map((i)=><tr>
                  <td>{i.item_name??""}</td>
                  <td>{i.quantity_sold??""}</td>
                  <td>{i.unit_cost??""}</td>
                  <td>{i.unit_sale_price??""}</td>
                  <td>{i.gross_profit_or_loss??""}</td>
                </tr>)}
                
                {/* <tr>
                  <td>Bajji Chilli</td>
                  <td>14</td>
                  <td>140</td>
                  <td>140</td>
                  <td>16</td>
                </tr>
                <tr>
                  <td>Banana</td>
                  <td>8</td>
                  <td>140</td>
                  <td>140</td>
                  <td>16</td>
                </tr>
                <tr>
                  <td>Big Potato-Jothi Grade A</td>
                  <td>9</td>
                  <td>140</td>
                  <td>140</td>
                  <td>16</td>
                </tr>
                <tr>
                  <td>Carrot</td>
                  <td>4</td>
                  <td>140</td>
                  <td>140</td>
                  <td>16</td>
                </tr> */}
              </tbody>
            </Table>
            <Stack spacing={2}>
              {/* Centered Pagination */}
              <Box display="flex" justifyContent="center" alignItems="center">
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ marginRight: "10px" }}
                >
                  {page} / {Math.ceil(props.dailyProducts.filter((item) =>
                    value
                      ? item.item_name
                          .toLowerCase()
                          .includes(value.toLowerCase())
                      : true
                  ).length / rowsPerPage)}
                </Typography>
                <Pagination
                  count={Math.ceil(props.dailyProducts.filter((item) =>
                    value
                      ? item.item_name
                          .toLowerCase()
                          .includes(value.toLowerCase())
                      : true
                  ).length / rowsPerPage)}
                  page={page}
                  size="small"
                  onChange={handlePaginationChange}
                  sx={{
                    "& .MuiPaginationItem-root": {
                      fontSize: "12px",
                      minWidth: "20px",
                    },
                  }}
                />
              </Box>
            </Stack>
          </div>: <NoItems /> }
          </div>
        </div>
      </div>
    </div>
  );
};


function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

function mapStateToProps(state) {
  return {
    dailySummary: state.insights.dailySummary??{},
    dailyProducts: state.insights.dailySummary?.daily_sales_summary?.product_sales??[],
    yearlySummary: state.insights.yearlySummary??{},
    annualOverview: state.insights.yearlySummary?.annual_overview??{},
    monthlyOverview: state.insights.yearlySummary?.monthly_performance??[],
    clients: state.procurement.clients ?? [],


  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Insights));
