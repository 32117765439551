import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import withRouter from "../Router/withRouter";
import { MainHeader } from "../../Components/header";
import Sidebar from "../../Components/sidebar";
import Navbar from "../../Components/navbar";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import Orders from "./orders";
import { getOrders, getClients } from "../../services/Actions/client";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import DatePicker from "react-datepicker";
import moment from "moment";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function HomeOrders(props) {
  const [rows, setRows] = useState({});
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedClient, setClients] = useState("");

  useEffect(() => {
    // props.dispatch(getOrders());
    if (props.clients.length > 0) {
      let id = props.clients[0].id;
      setClients(id);
      getDate(id);
    }
  }, [props.clients]);
  useEffect(() => {
    props.dispatch(getClients());
    const currentDate = moment();
    currentDate.subtract(2, 'days').format('YYYY-MM-DD');
    setStartDate(currentDate.toDate());
  }, []);
  const handleMonthChange = (date, setDate) => {
    setDate(date);
    // getData(date);
  };
  const handleSelectChange = (event) => {
    let id = event.target.value;
    setClients(id);
    getDate(id);
  };
  const onApply=()=>{
    getDate(selectedClient);
  }
  const getDate = (id) => {
    const from = moment(startDate).format("YYYY-MM-DD");
    const to = moment(endDate).format("YYYY-MM-DD");
    
    props.dispatch(getOrders(id,from,to));
  };
  return (
    // <div className="w-full">
    //   <div className="headerandsidebar">
    //     <MainHeader />
    //     <Sidebar />
    //   </div>
    //   <Navbar />
    <div className="main-container">
      <div className="container">
        <div className="row">

          <div className="productssection">
            <div className="d-flex">
            <div>
              <FormControl
                className="customdropdownsection"
                sx={{ m: 1, minWidth: 400 }}
              >
                <Select
                  value={selectedClient}
                  onChange={handleSelectChange} // Updated to handleSelectChange
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                   <MenuItem value="">All</MenuItem>
                  {props.clients?.map((i) => (
                    <MenuItem value={i.id}>{i.name ?? ""}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="fromDateSection align-items-center m-2">
              {/* <h5 className="me-3">From</h5> */}
              <DatePicker
                selected={startDate}
                placeholderText="dd/mm/yyyy"
                onChange={(date) => handleMonthChange(date, setStartDate)}
                // onYearChange={(date) => handleMonthChange(date)}

                dateFormat="dd/MM/yyyy"
                // showMonthYearPicker
                className="form-control"
              />
            </div>
            <div className="fromDateSection col-3 align-items-center mt-2">
              {/* <h5 className="me-3">From</h5> */}
              <DatePicker
                selected={endDate}
                placeholderText="dd/mm/yyyy"
                onChange={(date) => handleMonthChange(date, setEndDate)}
                // onYearChange={(date) => handleMonthChange(date)}

                dateFormat="dd/MM/yyyy"
                // showMonthYearPicker
                className="form-control"
              />
            </div>
            <div className="d-flex justify-content-between align-items-center">
                <button onClick={onApply} className="button-section">
                  Apply
                </button>
              </div>
            </div>
            {/* <div className="col-lg-12 mb-3">
              <h3 className="primaryheader">Today’s Orders</h3>
            </div> */}
            <Orders />
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
}
function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}
function mapStateToProps(state) {
  return {
    orders: state.procurement.orders ?? [],
    clients: state.procurement.clients ?? [],
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HomeOrders)
);
