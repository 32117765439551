import React, { useState, useEffect, startTransition } from "react";
import { connect } from "react-redux";
import withRouter from "../Router/withRouter";
import { MainHeader } from "../../Components/header";
import Sidebar from "../../Components/sidebar";
import Navbar from "../../Components/navbar";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import Costprice from "./costprice";
import { getProcurements, updatePrice } from "../../services/Actions/client";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import NoItems from "../Components/no_items";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function HomeCostPrize(props) {
  const [rows, setRows] = useState({});
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [starttime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    // getData();
    getData(startDate);
    // const from = moment(startDate).format('YYYY-MM-DD');

    //   props.dispatch(getProcurements("cost",from));
    // props.dispatch(getOrderIds());
  }, []);
  const getData = (date) => {
    const from = moment(date).format("YYYY-MM-DD");
    // const time1 = starttime? moment(starttime).format("HH:mm"):"";
    // const time2 = endTime?moment(endTime).format("HH:mm"):"";

    props.dispatch(getProcurements("cost", from));
  };
  useEffect(() => {
    setRows(props.costPrices);
  }, [props]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const onUpdateRows = (key, items) => {
    // let updatedRows=rows;
    // updatedRows[key]=items;
    let updatedRows = {
      ...rows,
      [key]: items,
    };
    // let updatedRows ={
    //   ...rows,
    //   key:items
    // }
    console.log("updatedRows0,", updatedRows);
    setRows(updatedRows);
  };

  const handleMonthChange = (date, setDate) => {
    setDate(date);
    getData(date);
  };
  const onUpdatePrice = () => {
    // "prices": result,
    let result = [];
    Object.values(rows)
      .flat()
      .map((item) => {
        // if (item.cost_price != 0) {
          result.push({
            product_id: item.product_id,
            order_ids:item.order_ids,
            cost_price: item?.cost_price??0,
          });
        // }
      });
    console.log("result", result);
    let payload = {
      prices: result,
      update_existing: true,
    };
    props.dispatch(updatePrice(payload));
  };

  const clickPrint = () => {
    //console.log('print');
    let printContents = document.getElementById("printablediv").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
  };
  return (
    // <div className="w-full">
    //   <div className="headerandsidebar">
    //     <MainHeader />
    //     <Sidebar />
    //   </div>
    //   <Navbar />
    <div className="main-container">
      <div className="container">
        <div className="row">
          <div className="productssection">
            <div className="col-lg-12 mb-3">
              <h3 className="primaryheader">COST PRICE</h3>
            </div>
<div className="col-8 d-flex mb-3">

            <div className="fromDateSection col-3 d-flex align-items-center me-5">
              {/* <h5 className="me-3">From</h5> */}
              <DatePicker
                selected={startDate}
                placeholderText="dd/mm/yyyy"
                onChange={(date) => handleMonthChange(date, setStartDate)}
                // onYearChange={(date) => handleMonthChange(date)}

                dateFormat="dd/MM/yyyy"
                // showMonthYearPicker
                className="form-control"
              />
            </div>
{/* <div className="startTimeSection col-3 d-flex align-items-center me-5">

            <DatePicker
              selected={starttime}
              onChange={(date) => handleMonthChange(date, setStartTime)}
              showTimeSelect
              showTimeSelectOnly
              placeholderText="start time"
              dateFormat="HH:mm"
              className="form-control"
            />
            </div>
            <div className="endTimeSection col-3 d-flex align-items-center me-5">

            <DatePicker
              selected={endTime}
              onChange={(date) => handleMonthChange(date, setEndTime)}
              showTimeSelect
              showTimeSelectOnly
              placeholderText="end time"
              dateFormat="HH:mm"
              className="form-control"
            />
</div> */}

            {/* <div className="col-6 d-flex justify-content-between align-items-center">
                  <button onClick={getData} className="button-section">Apply</button>
                </div> */}
                </div>
                {Object.entries(rows).length>0?<div>
            <div className="col-lg-12 d-flex align-items-center justify-content-between mb-5">
              <div className="inputseachsection col-4">
                <input
                  className="form-control mr-sm-2"
                  type="search"
                  placeholder="Search Products..."
                  aria-label="Search"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              <div className="d-flex justify-content-end">
                <Stack spacing={2}>
                  <Pagination
                    count={Math.ceil(Object.keys(rows).length / rowsPerPage)}
                    page={page}
                    onChange={handleChangePage}
                    showFirstButton
                    showLastButton
                  />
                </Stack>
              </div>
            </div>
            {Object.entries(rows).map(([key, value]) => {
              return (
                <Costprice
                  onUpdateRow={(key, items) => onUpdateRows(key, items)}
                  value={search}
                  name={key}
                  items={value.filter((product) =>
                    search
                      ? product.name
                          .toLowerCase()
                          .includes(search.toLowerCase())
                      : true
                  )}
                />
              );
            })}
            {/* <Costprice name={} />
              <Costprice />
              <Costprice />
              <Costprice /> */}
            <div className="col-lg-12 my-5 d-flex justify-content-center">
              <button
                onClick={onUpdatePrice}
                className=" col-4 nav-button-section"
              >
                Update Price
              </button>
              <button
                style={{ marginLeft: "20px" }}
                onClick={clickPrint}
                className=" col-4 nav-button-section"
              >
                Print
              </button>
            </div>
          </div>: <NoItems />}
          </div>
        </div>
      </div>
      <div className="d-none" id="printablediv">
        {Object.entries(rows).map(([key, value]) => {
          return (
            <Costprice
              onUpdateRow={(key, items) => onUpdateRows(key, items)}
              value={search}
              name={key}
              items={value.filter((product) =>
                search
                  ? product.name.toLowerCase().includes(search.toLowerCase())
                  : true
              )}
            />
          );
        })}
      </div>
    </div>
    // </div>
  );
}
function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}
function mapStateToProps(state) {
  return {
    costPrices: state.procurement.costPrices ?? {},
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HomeCostPrize)
);
