import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import withRouter from "../Router/withRouter";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import BackIcon from "../../assets/icons/back-icon.svg";
import {addOprational} from "../../services/Actions/insights_operational";

function OperationalSection(props) {

const [val, setval]= useState("");
  const [payload, setPayload] = useState({
    "current_capacity_in_use": "",
    "max_warehouse_capacity": "",
    "labor_cost_per_person": "",
    "max_laborers": "",
    "manager_salary": "",
    "rent": "",
    "electricity_bill": "",
    "founders_salary": "",
    "variable_cost_transportation": "",
    "month":"",
    "year":""
});
useEffect(() => {
//   if(props.setOperational){
//     let i=props.setOperational;
//    let payload={
//     "current_capacity_in_use": "",
//     "max_warehouse_capacity": "",
//     "labor_cost_per_person": "",
//     "max_laborers": "",
//     "manager_salary": "",
//     "rent": "",
//     "electricity_bill": "",
//     "founders_salary": "",
//     "variable_cost_transportation": i.variable_cost_transportation,
//     "month":i.month,
//     "year":i.year
// };
//   }
}, [props.setOperational]);
const onUpdateDetails = (e) => {
  const nextFormState = {
    ...payload,
    [e.target.name]: e.target.value,
  };
  setPayload(nextFormState);
};

const submitData =()=> {
  const convertedObject = Object.fromEntries(
    Object.entries(payload).map(([key, value]) => [key, Number(value) || 0]) // Convert each value to a number, defaulting to 0 for non-numeric or empty values
  );
  props.dispatch(addOprational(convertedObject)).then(()=>{
    props.router.navigate(-1)
  });
}


  return (
    <div className="w-full">
      <div className="main-container clientdetailssection">
        <div className="container">
          <div className="row">
            <div
              onClick={() => props.router.navigate(-1)}
              class="col-12 d-flex justify-content-start align-items-center"
              style={{ marginBottom: "20px" }}
            >
              <img src={BackIcon} alt="icon" className="gobackicon"></img>
              <h6>Go Back</h6>
            </div>
            <div className="operationaldivsection">
              <div className="w-full">
                <div className="operationalSectiontablesection d-flex justify-content-center">
                  <div className="col-lg-10 d-flex align-items-center flex-column">
                    <div className="col-lg-12 d-flex align-items-center operationalSectioninputsection">
                      <h3 class="primaryheader">
                        Fixed Cost
                      </h3>
                    </div>
                    <div className="col-lg-12 operationalSectioninputsection">
                      <div className="col-lg-12 operationallabelsection">
                        Current Capacity in Use (INR)
                      </div>
                      <div className="col-lg-12">
                        <Box
                          sx={{
                            width: "100%",
                            maxWidth: "100%",
                            backgroundColor: "#fff",
                            borderRadius: "13px",
                            border: "1px solid #000",
                          }}
                        >
                          <TextField type="number" name="current_capacity_in_use" fullWidth id="fullWidth" value={payload.current_capacity_in_use || ""} onChange={onUpdateDetails} />
                        </Box>
                      </div>
                    </div>
                    <div className="col-lg-12 operationalSectioninputsection">
                      <div className="col-lg-12 operationallabelsection">
                        Max Warehouse Capacity (tons)
                      </div>
                      <div className="col-lg-12">
                        <Box
                          sx={{
                            width: "100%",
                            maxWidth: "100%",
                            backgroundColor: "#fff",
                            borderRadius: "13px",
                            border: "1px solid #000",
                          }}
                        >
                          <TextField type="number" name="max_warehouse_capacity" fullWidth id="fullWidth" value={payload.max_warehouse_capacity || ""} onChange={onUpdateDetails}/>
                        </Box>
                      </div>
                    </div>
                    {/* <div className="col-lg-12 operationalSectioninputsection">
                      <div className="col-lg-12 operationallabelsection">
                        Number of Clients
                      </div>
                      <div className="col-lg-12">
                        <Box
                          sx={{
                            width: "100%",
                            maxWidth: "100%",
                            backgroundColor: "#fff",
                            borderRadius: "13px",
                            border: "1px solid #000",
                          }}
                        >
                          <TextField name="name" fullWidth id="fullWidth" />
                        </Box>
                      </div>
                    </div> */}
                    <div className="col-lg-12 operationalSectioninputsection">
                      <div className="col-lg-12 operationallabelsection">
                        Labour Cost Per Person (INR)
                      </div>
                      <div className="col-lg-12">
                        <Box
                          sx={{
                            width: "100%",
                            maxWidth: "100%",
                            backgroundColor: "#fff",
                            borderRadius: "13px",
                            border: "1px solid #000",
                          }}
                        >
                          <TextField type="number" name="labor_cost_per_person" fullWidth id="fullWidth" value={payload.labor_cost_per_person || ""} onChange={onUpdateDetails} />
                        </Box>
                      </div>
                    </div>
                    <div className="col-lg-12 operationalSectioninputsection">
                      <div className="col-lg-12 operationallabelsection">
                        Max Labourers
                      </div>
                      <div className="col-lg-12">
                        <Box
                          sx={{
                            width: "100%",
                            maxWidth: "100%",
                            backgroundColor: "#fff",
                            borderRadius: "13px",
                            border: "1px solid #000",
                          }}
                        >
                          <TextField type="number" name="max_laborers" fullWidth id="fullWidth" value={payload.max_laborers || ""} onChange={onUpdateDetails} />
                        </Box>
                      </div>
                    </div>
                    <div className="col-lg-12 operationalSectioninputsection">
                      <div className="col-lg-12 operationallabelsection">
                        Manager Salary (INR)
                      </div>
                      <div className="col-lg-12">
                        <Box
                          sx={{
                            width: "100%",
                            maxWidth: "100%",
                            backgroundColor: "#fff",
                            borderRadius: "13px",
                            border: "1px solid #000",
                          }}
                        >
                          <TextField type="number" name="manager_salary" fullWidth id="fullWidth" value={payload.manager_salary || ""} onChange={onUpdateDetails} />
                        </Box>
                      </div>
                    </div>
                    <div className="col-lg-12 operationalSectioninputsection">
                      <div className="col-lg-12 operationallabelsection">
                        Rent (INR)
                      </div>
                      <div className="col-lg-12">
                        <Box
                          sx={{
                            width: "100%",
                            maxWidth: "100%",
                            backgroundColor: "#fff",
                            borderRadius: "13px",
                            border: "1px solid #000",
                          }}
                        >
                          <TextField type="number" name="rent" fullWidth id="fullWidth" value={payload.rent || ""} onChange={onUpdateDetails} />
                        </Box>
                      </div>
                    </div>
                    <div className="col-lg-12 operationalSectioninputsection">
                      <div className="col-lg-12 operationallabelsection">
                        Electricity Bill (INR)
                      </div>
                      <div className="col-lg-12">
                        <Box
                          sx={{
                            width: "100%",
                            maxWidth: "100%",
                            backgroundColor: "#fff",
                            borderRadius: "13px",
                            border: "1px solid #000",
                          }}
                        >
                          <TextField type="number" name="electricity_bill" fullWidth id="fullWidth" value={payload.electricity_bill || ""} onChange={onUpdateDetails} />
                        </Box>
                      </div>
                    </div>
                    <div className="col-lg-12 operationalSectioninputsection">
                      <div className="col-lg-12 operationallabelsection">
                        Founders Salary (INR)
                      </div>
                      <div className="col-lg-12">
                        <Box
                          sx={{
                            width: "100%",
                            maxWidth: "100%",
                            backgroundColor: "#fff",
                            borderRadius: "13px",
                            border: "1px solid #000",
                          }}
                        >
                          <TextField type="number" name="founders_salary" fullWidth id="fullWidth" value={payload.founders_salary || ""} onChange={onUpdateDetails}/>
                        </Box>
                      </div>
                    </div>
                    <div className="col-lg-12 operationalSectioninputsection">
                      <div className="col-lg-12 operationallabelsection">
                      Variable Cost Transportation (INR)
                      </div>
                      <div className="col-lg-12">
                        <Box
                          sx={{
                            width: "100%",
                            maxWidth: "100%",
                            backgroundColor: "#fff",
                            borderRadius: "13px",
                            border: "1px solid #000",
                          }}
                        >
                          <TextField type="number" name="founders_salary" fullWidth id="fullWidth" value={payload.variable_cost_transportation || ""} onChange={onUpdateDetails}/>
                        </Box>
                      </div>
                    </div>
                    <div className="col-lg-12 d-flex align-items-center">
                      <div className="col-lg-6 pe-3">
                        <div className="col-lg-12 operationallabelsection">
                        Month
                        </div>
                        <div className="col-lg-12">
                          <Box
                            sx={{
                              width: "100%",
                              maxWidth: "100%",
                              backgroundColor: "#fff",
                              borderRadius: "13px",
                              border: "1px solid #000",
                            }}
                          >
                            <TextField type="number" name="month" fullWidth id="fullWidth" value={payload.month || ""} onChange={onUpdateDetails} />
                          </Box>
                        </div>
                      </div>
                      <div className="col-lg-6 ps-3">
                        <div className="col-lg-12 operationallabelsection">
                        Year
                        </div>
                        <div className="col-lg-12">
                          <Box
                            sx={{
                              width: "100%",
                              maxWidth: "100%",
                              backgroundColor: "#fff",
                              borderRadius: "13px",
                              border: "1px solid #000",
                            }}
                          >
                            <TextField type="number" name="year" fullWidth id="fullWidth" value={payload.year || ""} onChange={onUpdateDetails}/>
                          </Box>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12 my-5 d-flex justify-content-center">
                      <button onClick={submitData} class="nav-button-section">SUBMIT</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

function mapStateToProps(state) {
  return {
    clients: state.procurement.clients ?? [],
    setOperational: state.insights.setOperational,

  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OperationalSection)
);
