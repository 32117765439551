import React from "react";
import { useDateSelect } from "react-ymd-date-select";

function CustomYearSelect({ value, onChange }) {
  const { yearValue, yearOptions, onYearChange } = useDateSelect(value, onChange);

  return (
    <label className="customyearselectionsection">
      <select className="yearSelectionSection" value={yearValue} onChange={onYearChange}>
        {yearOptions.map((yearOption) => (
          <option key={yearOption.value} value={yearOption.value}>
            {yearOption.label}
          </option>
        ))}
      </select>
    </label>
  );
}

export default CustomYearSelect;