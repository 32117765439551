import React, {useState, useEffect} from "react";
import { connect } from "react-redux";
import withRouter from "../Router/withRouter";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

function createData(name, details) {
  return { name, details };
}

const initialRows = [
  createData("INVOICE DATE", ""),
  createData("CLIENT NAME", ""),
  createData("COMPANY NAME", ""),
  createData("GST ID", ""),
  createData("ORDER ID", ""),
];

function Invoice(props) {
  const [rows, setRows] = useState(initialRows);

  useEffect(() => {
    let i=props.prepareInvoices;

    let val={};
    if(props.type=="D.C"){
    let u=props.prepareDC;

      val={
        "client":u?.clientDetails?.ClientName??"",
        "company":u?.clientDetails?.CompanyName??"",
        "gst":u?.clientDetails?.GSTID??"",
        "order_number":u?.challan?.order_number??"",
        "date":u?.challan?.created_at??"",

      }
    }else{
      val={
        "client":i?.client_name??"",
        "company":i?.company_name??"",
        "gst":i?.gst_id??"",
        "order_number":i?.order?.order_number??"",
        "date":i?.created_at??"",

      }
    }

let row = [
  createData(`${props.type} DATE`, val?.date??""),
  createData("CLIENT NAME", val?.client??""),
  createData("COMPANY NAME", val?.company??""),
  createData("GST ID",val?.gst??""),
  createData("ORDER ID", val?.order_number??""),
];
    setRows(row);
  }, [props]);
  return (
    <div className="w-full">
      <div className="invoicetablesection">
        <div className="col-6">
          <TableContainer component={Paper} style={{ borderRadius: "20px", backgroundColor: "transparent", boxShadow: "none !important" }}>
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              style={{ backgroundColor: "transparent !important" }}
            >
              <TableHead>
                <TableCell>{props.type}</TableCell>
                <TableCell></TableCell>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="left">{row.details}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

function mapStateToProps(state) {
  return {
    prepareInvoices: state.procurement.prepareInvoices??{},
    prepareDC: state.invoice.prepareDC??{}

  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Invoice));
